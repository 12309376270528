import {useState} from 'react';
// @mui
import {MenuItem, Stack} from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
// components
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import {IconButtonAnimate} from '../../../components/animate';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
    const {allLang, currentLang, onChangeLang} = useLocales();

    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    return (
        <>
            <IconButtonAnimate
                onClick={handleOpen}
                sx={{
                    width: 40,
                    height: 40,
                    ...(open && {bgcolor: 'action.selected'}),
                }}
            >
                <Image disabledEffect src={currentLang.icon} alt={currentLang.label}
                       sx={{width: '100%'}}/>
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    '& .MuiMenuItem-root': {px: 1, typography: 'body2', borderRadius: 0.75},
                }}
            >
                <Stack spacing={0.75}>
                    {allLang.map((option) => (
                        <MenuItem
                            key={option.value}
                            selected={option.value === currentLang.value}
                            onClick={() => {
                                console.log('change lang', option.value)
                                onChangeLang(option.value);
                                handleClose();
                            }}
                        >
                            <Stack sx={{width: 28, height: 22, mr: 2}}>
                                <Image disabledEffect src={option.icon} alt={option.label}
                                       sx={{width: '100%'}}/>
                            </Stack>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>
            </MenuPopover>
        </>
    );
}
