import {useState} from 'react';

// ----------------------------------------------------------------------

export default function useLocalStorage(key, defaultValue, forceDefaults, notJSON) {
    const [value, setValue] = useState(() => {
        const storedValue = localStorage.getItem(key);
        if (forceDefaults) {
            return defaultValue;
        }
        return storedValue === null ? defaultValue : notJSON ? storedValue : JSON.parse(storedValue);
    });

    const setValueInLocalStorage = (newValue) => {
        const result = typeof newValue === 'function' ? newValue(value) : newValue;
        setValue(result);
        if (!notJSON) {
            localStorage.setItem(key, JSON.stringify(result));
        } else {
            localStorage.setItem(key, result);
        }
    };

    return [value, setValueInLocalStorage];
}
