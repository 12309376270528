import axios from "./axios";
import {parse} from "node-html-parser";
import {Text, View} from "@react-pdf/renderer";
import React from "react";
import {BlobServiceClient} from '@azure/storage-blob';

const getSasToken = async (container, blobName) => {
    const params = {
        container,
        blobName
    }
    const response = await axios.get(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/sas/token`, {params})
    return response.data.token;
};

export const getContentSasToken = async (container, blobName) => {
    const params = {
        container,
        blobName
    }
    const response = await axios.get(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/content/sas/token`, {params})
    return response.data.token;
};

export const uploadFileToBlob = async (file, blobName, containerName, onProgressCallback = () => {
}) => {
    const {token: sasToken, url} = await getSasToken(containerName, blobName);

    const blobServiceClient = new BlobServiceClient(`${url}?${sasToken}`, null);

    // create container client
    const containerClient = await blobServiceClient.getContainerClient(containerName);

    // create blob client
    const blobClient = await containerClient.getBlockBlobClient(blobName);

    return await blobClient.uploadData(file, {
        blobHTTPHeaders: {blobContentType: file.type},
        onProgress: (progress) => {
            // progress.loadedBytes will give the number of bytes uploaded
            onProgressCallback(progress.loadedBytes, file.size);
        }
    });
};


export const uploadContentToBlob = async (file, blobName, containerName, onProgressCallback = () => {
}) => {
    const {token: sasToken, url} = await getContentSasToken(containerName, blobName);

    const blobServiceClient = new BlobServiceClient(`${url}?${sasToken}`, null);

    // create container client
    const containerClient = await blobServiceClient.getContainerClient(containerName);

    if (!await containerClient.exists()) {
        await containerClient.create()
    }

    // create blob client
    const blobClient = await containerClient.getBlockBlobClient(blobName);

    return await blobClient.uploadData(file, {
        blobHTTPHeaders: {blobContentType: file.type},
        onProgress: (progress) => {
            // progress.loadedBytes will give the number of bytes uploaded
            onProgressCallback(progress.loadedBytes, file.size);
        }
    });
};

export const buildDocumentPath = (activeOrganization, currentFolder) => currentFolder ? `documents/${activeOrganization._id}/${currentFolder}` : `documents/${activeOrganization._id}`

export const buildContentPath = (activeOrganization, currentFolder) => currentFolder ? `content/${activeOrganization._id}/${currentFolder}` : `content/${activeOrganization._id}`

export const uploadFile = async (path, fileName, file, isDocument = false) => {
    const formData = new FormData();
    formData.append('file', file);

    const {
        documentType,
        fileType,
        labels,
        size,
        effectiveDate,
        expirationDate,
        friendlyName,
        version,
        city,
        county,
        state,
        federal
    } = file

    const params = {
        friendlyName,
        documentType,
        fileType,
        size,
        isDocument,
        effectiveDate,
        expirationDate,
        version,
        labels: labels?.join(',') || [],
        city,
        county,
        state,
        federal,
        path,
        fileName: fileName,
        container: process.env.REACT_APP_ENV,
        code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
    }

    if (file._id) {
        params._id = file._id;
    }

    return axios.post(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            params
        });
};

export const deleteFile = async (_id, path, fileName, isDocument = false) => {
    const params = {
        _id,
        fileName,
        path,
        isDocument,
        container: process.env.REACT_APP_ENV,
        code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
    }
    return axios.delete(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files`,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            params
        });
}

export const parseHTML = (htmlString) => {
    const root = parse(htmlString);

    const convertNode = (node) => {
        if (node.nodeType === 1) { // 1 for element nodes
            const style = {
                // Add styles here based on HTML attributes or element types
                fontWeight: node.rawTagName === 'strong' ? 'bold' : 'normal',
                // You can add more styles as needed
            };

            const elementProps = {
                style,
                key: node.childNodes.length ? undefined : node.rawText,
            };

            if (node.rawTagName === 'ul') {
                return (
                    <View {...elementProps}>
                        {node.childNodes.map((child) => convertNode(child))}
                    </View>
                );
            } else if (node.rawTagName === 'ol') {
                return (
                    <View {...elementProps}>
                        {node.childNodes.map((child, index) => (
                            <Text key={index} style={{marginLeft: 10}}>
                                {`${index + 1}. `}
                                {convertNode(child)}
                            </Text>
                        ))}
                    </View>
                );
            }

            return (
                <Text {...elementProps}>
                    {node.childNodes.map((child) => convertNode(child))}
                </Text>
            );
        } else if (node.nodeType === 3) { // 3 for text nodes
            return node.rawText;
        }

        return null;
    };

    return root.childNodes.map((node) => convertNode(node));
};

export const base64ToFile = (base64String, fileName) => {
    // Remove the data:image/jpeg;base64, part
    const base64Data = base64String.split(',')[1];

    // Decode base64 data to binary string
    const byteCharacters = atob(base64Data);

    // Create an array for byte numbers
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Create a blob from byte numbers
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: 'image/jpeg'});

    // Create a file from the blob
    const file = new File([blob], fileName, {type: 'image/jpeg'});

    // Generate a preview URL
    const preview = URL.createObjectURL(file);

    return Object.assign(file, {
        preview
    })
};

export const base64ToBlob = (base64, mimeType) => {
    try {
        const byteCharacters = atob(base64); // Decode base64
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: mimeType}); // Create the Blob
        return blob;
    } catch (error) {
        console.error("Error decoding base64 string:", error);
        return null;
    }
};

export const blobToFile = (blob, fileName) => {
    return new File([blob], fileName, {
        type: blob.type,
        lastModified: Date.now()
    });
}
