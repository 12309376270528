// routes
import {useLocation} from "react-router-dom";
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import {AlertsProvider} from "./contexts/AlertsContext";
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import {ChartStyle} from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import {ProgressBarStyle} from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import axios from "./utils/axios";
import axiosPlain from 'axios'
import {SessionProvider} from "./contexts/SessionContext";
import PermissionsProvider from "./contexts/PermissionsContext";
import {NavigationProvider} from "./contexts/NavigationContext";
import Imports from "./components/imports";
import {WorkStatusProvider} from "./contexts/WorkStatusContext";
import {useEffect} from "react";
// ----------------------------------------------------------------------

export default function App() {
    const {pathname, state = {}} = useLocation();
    
    useEffect(() => {
        if (process.env.REACT_APP_ENV !== 'local') {
            axiosPlain.get('/version.json')
                .then(({data}) => {
                    console.log('Check version available...', {
                        'data.version': data.version,
                        'process.env.REACT_APP_VERSION': process.env.REACT_APP_VERSION
                    });
                    if (data.version !== process.env.REACT_APP_VERSION) {
                        window.location.reload();
                    }
                });
            setInterval(() => {
                axiosPlain.get('/version.json')
                    .then(({data}) => {
                        console.log('Check version available...', {
                            'data.version': data.version,
                            'process.env.REACT_APP_VERSION': process.env.REACT_APP_VERSION
                        });
                        if (data.version !== process.env.REACT_APP_VERSION) {
                            console.log('New version available. Reloading...');
                            localStorage.setItem('reload-status', 'true');
                        }
                    });
            }, 5 * 60 * 1000);
        }
    }, []);

    window.onload = function () {
        // Set a value when the window loads
        window.localStorage.setItem('current-version', process.env.REACT_APP_VERSION)
    };
//message, source, lineno, colno, error
    window.onerror = function (message, url, line, colno, error) {
        // Check if it's a ChunkLoadError
        if (message && message.includes('Loading chunk')) {
            console.log('Chunk loading error detected, reloading the page...');
            window.location.reload(); // Reload the page to load new chunks
            return;
        }

        // Capture error as support request
        if (process.env.REACT_APP_ENV !== 'local') {

            const localStorageData = {};
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                localStorageData[key] = localStorage.getItem(key);
            }

            axios.put(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/support/request`, {
                foundInVersion: process.env.REACT_APP_VERSION,
                name: 'Issue captured in app',
                description: `Path: ${url} 

                Page: ${window.location.href}
                Line: ${line} 
                Col No.: ${colno} 
                Error: ${message.toString()}
                Stack: ${error.stack}
                Page: ${window.location.href}
                State: ${JSON.stringify(state)}`,
                type: "💥 Bug",
                prioritize: 'high'
            }, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                }
            })
                .then((response) => {

                })
                .catch((error) => {
                    console.error(error)
                })
        } else {

            console.error(`Path: ${url} 
                
                Line: ${line} 
                Col No.: ${colno} 
                Error: ${message.toString()}
                Stack: ${error.stack}
                Page: ${window.location.href}`)

            console.error(JSON.stringify({error}))
        }
    };

    return (
        <ThemeProvider>
            <ThemeColorPresets>
                <ThemeLocalization>
                    <SessionProvider>
                        <PermissionsProvider>
                            <NavigationProvider>
                                <RtlLayout>
                                    <NotistackProvider>
                                        <AlertsProvider>
                                            <WorkStatusProvider>
                                                <MotionLazyContainer>
                                                    <ProgressBarStyle/>
                                                    <ChartStyle/>
                                                    <Imports/>
                                                    <Settings isDashboard={pathname.includes('/dashboard')}/>
                                                    <ScrollToTop/>
                                                    <Router/>
                                                </MotionLazyContainer>
                                            </WorkStatusProvider>
                                        </AlertsProvider>
                                    </NotistackProvider>
                                </RtlLayout>
                            </NavigationProvider>
                        </PermissionsProvider>
                    </SessionProvider>
                </ThemeLocalization>
            </ThemeColorPresets>
        </ThemeProvider>
    );
}
